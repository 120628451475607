// app/javascript/controllers/scroll_to_controller.js
//h ttps://boringrails.com/articles/self-destructing-stimulus-controllers/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    location: String
  }

  connect() {
    this.targetElement.scrollIntoView({behavior: "smooth"})
    this.element.remove()
  }

  get targetElement() {
    return document.getElementById(this.locationValue)
  }
}
