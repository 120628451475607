// app/javascript/controllers/highlighter_controller.js
// https://boringrails.com/articles/self-destructing-stimulus-controllers/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    marker: String
  }
  static classes = ["kte_highlight"]

  connect() {
    this.markedElement.classList.add(...this.kte_highlightClasses)
    this.element.remove()
  }

  get markedElement() {
    return document.getElementById(this.markerValue)
  }
}
