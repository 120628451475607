import { Controller } from 'stimulus'

// Import TinyMCE
import tinymce from 'tinymce/tinymce'

import nonbreaking from 'tinymce/plugins/nonbreaking';
import image from 'tinymce/plugins/image';


export default class extends Controller {
  static targets = ['input']
  static values = {
    url: String
  }


  initialize () {
    this.default = {
      images_upload_url: '/admin/tinymce_assets',
      file_picker_types: 'file image media',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: this.urlValue,
      automatic_uploads: true,
      // v5 -> v6 changed from imagetools_cors_hosts to editimage_cors_hosts
      editimage_cors_hosts: ['picsum.photos'],
      image_advtab: true,
      image_caption: true,
      base_url: process.env.ASSET_PATH + 'tinymce',
      plugins:
      /* previously the 'file' plugin was included - doesn't exist anymore. */
        'image link media preview importcss autolink autosave save directionality code visualblocks visualchars fullscreen table charmap pagebreak nonbreaking anchor advlist lists help',
      /* Default v6 menubar is all this plus 'help'... no need to specify
       * menubar: 'file edit view insert format tools table',
       */
      /* Default v6 toolbar is 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent'
       * What was here before, had things from previous versions...
       * toolbar:
       *   'image file media link | undo redo | fullscreen | bold italic underline strikethrough blockquote | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist preview | forecolor backcolor removeformat| ltr rtl | anchor charmap  ',
       */
      toolbar:
        'image media link | undo redo | fullscreen | bold italic underline strikethrough blockquote | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist preview | forecolor backcolor removeformat | ltr rtl | anchor charmap | fontfamily fontsize blocks',
      // Fails as of 6.4.0
      //toolbar_sticky: true,
      suffix: '.min',
      link_context_toolbar: true,
      link_list: '/content_uploads',
      link_title: false,
      link_target_list: [
        { title: 'None', value: '_top' },
        { title: 'Same page', value: '_top' },
        { title: 'New page', value: '_blank' }
      ],
      // Hide the Upgrade promotion button as of 6.2.0
      promotion: false,
    }


  }

  connect () {
    // Initialize the app

    if (this.data.get("attachesImages") == "images") {
      let config = Object.assign({ target: this.inputTarget }, this.default)
      tinymce.init(config)
    } else {
      let config = Object.assign({ target: this.inputTarget }, this.default)
      tinymce.init(config)
    }

  }

  disconnect () {
    tinymce.remove()
  }
}

      // Example of use

      // <div class="form-group" data-controller='tinymce' data-tinymce-url-value='<%= root_url %>'>
      //    <%= f.label :description %>
      //    <%= f.text_area :description, data: { tinymce_target: 'input' } %>
      // </div>


      // <div class="form-group" data-controller='tinymce' data-tinymce-url-value='<%= root_url %>'>
      //    <%= f.label :notes %>
      //    <%= f.text_area :notes, data: { tinymce_target: 'input' } %>
      // </div>



