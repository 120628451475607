import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    console.log("Stimulus at your service!")
  }

  connect() {
    console.log("connected to forms controller");
  }

  handleChange() {
    console.log("the dropdown changed");
  }
}