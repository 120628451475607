// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// import ModalController from "./modal_controller.js"
// application.register("modal", ModalController)

import TurbodrController from "./turbodr_controller.js"
application.register("turbodr", TurbodrController)

import Ts__SelectController from "./ts/select_controller.js"
application.register("ts--select", Ts__SelectController)

