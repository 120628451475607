import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formcontent", "category"];

  connect() {
    //console.log(this.categoryTarget.value);
    if (this.categoryTarget.value === "publication")
      { this.formcontentTarget.style.display = "block"; }
    else
      { this.formcontentTarget.style.display = "none"; }
  }

  handleChange() {
    //console.log(this.categoryTarget.value);
    if (this.categoryTarget.value === "publication")
      { this.formcontentTarget.style.display = "block"; }
    else
      { this.formcontentTarget.style.display = "none"; }
  }

}