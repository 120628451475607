function update_querystring(updates) {
  var parts = window.location.href.split('?');
  var qs = {};
  if(parts[1]) {
    var vars = parts[1].split('&');
    for (var i in vars) {
      var comps = vars[i].split('=');
      qs[comps[0]] = comps[1];
    }
  }
  for(k in updates) {
    qs[k] = updates[k];
  }
  return qs;
}

// fades out the flash message 
setTimeout(function() {
  $('#flash_messages').fadeOut('slow');}, 10000
);


// kinda just threw this together; it could probably use a little more thought -dt
function update_paginator_links(links, pageNum, showNearest) {
  var numPages = $('a', links).length
  var first, last;
  
  // remove gaps
  $('.gap', links).remove();
  
  $('a', links).each(function() {
    num = parseInt($(this).html());
    
    // update current page number
    if(num == pageNum) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
    
    // store first and last pages for gap insertion
    if(num == 1) {
      first = $(this);
    }
    if(num == numPages) {
      last = $(this);
    }
    
    // hide pages that are not within range or first, last
    if(num != 1 && num != numPages && (num < pageNum-showNearest || num > pageNum+showNearest)) {
      $(this).hide();
    } else {
      $(this).show();
    }
  });
  
  // re-insert gaps
  if(pageNum-showNearest > 2) {
    gap = $('<span class="gap">...</span>');
    gap.insertAfter(first);
  }
  if(pageNum+showNearest < numPages-1) {
    gap = $('<span class="gap">...</span>');
    gap.insertBefore(last);
  }
}

$.fn.viaAjax = function(options) {
  if(!options) options = {};

  this.submit(function(e) {
    var form = $(this),
        data = form.serializeArray(); // get data BEFORE disabling

    if(options.disable) {
      // disable to avoid multiple submission
      var elements = $('button:enabled, input:enabled, textarea:enabled', this);
      elements.attr('disabled', 'disabled');
    }

    $.ajax($.extend(options, {
      beforeSend: function(xhr) { xhr.setRequestHeader("Accept","application/javascript"); },
      url: form.attr('action') || window.location.href,
      data: data,
      type: form.attr('method') || 'GET',
      complete: function() {
        if(options.disable) {
          elements.removeAttr('disabled'); // re-enable elements
        }
      }
    }));

    return false;
  });
};

$.fn.extend({
  insertAtCaret: function(text) {
    this.each(function() {
      var scrollPos = this.scrollTop;
      var strPos = 0;
      var compliant = 'selectionStart' in this;

      if(compliant) {
        strPos = this.selectionStart;
      } else {
        this.focus();
        var range = document.selection.createRange();
        range.moveStart ('character', -this.value.length);
        strPos = range.text.length;
      }

      var front = this.value.substring(0,strPos);
      var back = this.value.substring(strPos, this.value.length);

      this.value = front+text+back;
      strPos = strPos + text.length;

      if(compliant) {
        this.selectionStart = strPos;
        this.selectionEnd = strPos;
        this.focus();
      } else {
        this.focus();
        var range = document.selection.createRange();
        range.moveStart ('character', -txtarea.value.length);
        range.moveStart ('character', strPos);
        range.moveEnd ('character', 0);
        range.select();
      }

      this.scrollTop = scrollPos;
    });
  }
});

$.fn.extend({
  gmap: function() {
    $(this).each(function() {
      var element = $(this);
      var data = $.parseJSON(element.attr('data-gmap'));
      var options = {
        zoom: data['zoom'],
        center: new google.maps.LatLng(data['lat'],data['lng']),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      if(data.href){
        $.getJSON(data.href,function(){
          $.merge(options,data);
        });
      }

      var map = new google.maps.Map(element[0],options);

      var bounds = new google.maps.LatLngBounds();

      $.each(data.marker,function(){
        var html = this.html;

        var marker = new google.maps.Marker({
		  icon: this.icon,
          position: new google.maps.LatLng(this.lat,this.lng),
          map: map,
          title: this.title
        });

        bounds.extend(marker.getPosition());

        if(html){

          var infowindow = new google.maps.InfoWindow({
            content: html
          });

          if(this.autoload){
            infowindow.open(map,marker);
          }else{
            google.maps.event.addListener(marker, 'click', function() {
               infowindow.open(map,marker);

            });
          }
        }
      });

      if (data.marker.length > 1)
      {
        map.fitBounds(bounds);
      }

      return map;
    });
  }
});

function h264video() {
  var v = document.createElement('video');
  return !!(v.canPlayType && v.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"').replace(/no/, ''));
}

function unobscure(el) {
    $('a.obscuredEmail', el).each(function() {
        $(this).attr("href", $('<div/>').html($(this).attr("href")).text());
        $(this).html($(this).text());
        $(this).attr('title', 'email '+$(this).text());
        $(this).removeClass('obscuredEmail');
    });
    $('.obscured').each(function() {
        $(this).html($(this).text());
        $(this).removeClass('obscured');
    });
}

function tagit_search(el) {
  $(el).tagit({
    availableTags: ALL_TAGS,
    restricted: true,
    allowSpaces: true,
    caseSensitive: false,
    placeholderText: 'enter tags here'
  });
}

function tagit_mutate(el, taggable_id, taggable_type) {
  var field = $(el);
  field.tagit({
    placeholderText: 'add a tag',
    availableTags: ALL_TAGS,
    restricted: true,
    allowSpaces: true,
    caseSensitive: false,
    backspaceRemove: false,
    
    onTagAdded: function(event, label) {  
      if(taggable_id != undefined && taggable_type != undefined) {
        $.ajax({
          url: '/taggings',
          type: 'POST',
          data: {taggable_type: taggable_type, taggable_id: taggable_id, tag_id: field.tagit('tagId', label)},
          success: function(data) {
            if(data['status']) {
              field.tagit('appendTag', label);
            }
          }
        });
      }
      return false;
    },
    
    onTagRemoved: function(event, tag) {
      if(taggable_id != undefined && taggable_type != undefined) {
        $.ajax({
          url: '/taggings/remove',
          type: 'POST',
          data: {taggable_type: taggable_type, taggable_id: taggable_id, tag_id: field.tagit('tagId', tag)},
          success: function(data) {
            if(data['status']) {
              field.tagit('removeTag', tag);
            }
          }
        });
      }
      return false;
    }
  });
}

function tagit_readonly(el) {
  // tag box that simply display tags-- does not provide add/delete controls
  $(el).tagit({
    availableTags: ALL_TAGS,
    restricted: true,
    caseSensitive: false,
    allowRemove: false,
    allowAdd: false
  });
}

// Gregs implementation of javascript field removal (see application helper)
function remove_fields(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".fields").hide();
}

function add_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  $(link).parent().before(content.replace(regexp, new_id));
}

function check_pmid(field) {
    $('#add_as_author').remove();
    $('#auth_msg').remove();
    if(field.value.length > 0) {
        $.get('/publications/'+field.value+'/exists', function(response) {
            if(response['exists']) {
                var button = $('<input type="button" id="add_as_author" value="Add Yourself as an Author"/>');
                $('<div id="auth_msg"><p>Publication with this pmid already exists.</p></div>').append(button).insertAfter(field);
                button.click(function() {
                    $.ajax({
                        url: '/publication_users.json',
                        type: 'POST',
                        data: {publication_user: response['new_publication_user']},
                        success: function(res) { 
                            if(res['success']) {
                                window.location.href = '/publications';
                            } else {
                                alert('Failed to add you as a user.');
                            }
                        },
                        failure: function() { alert('Something went wrong'); }
                    });
                });
            }
        });
    }
}


































// this function will be called on `document` when ready, and on any data
// loaded in via data-href once it has been loaded. make sure to use 
// the local find() method instead of $.find or $() in the main body,
// since it will include the root element as well if that behavior is 
// specified
function liveBehaviors(include_root) {

  var base = $(this);

  var find = function(selector) {
    var matched = base.find(selector);
    return include_root ? matched.add(base.filter(selector)) : matched;
  }
  
  find('[data-seltoggle]').each(function() {
    var that = $(this);
    var target = $(that.attr('data-seltoggle'));
    var text = that.attr('data-seltoggletext');
    if(typeof text == 'undefined') {
      text = target.is(":visible") ? 'show' : 'hide';
    }
    that.click(function(e) {
      e.preventDefault();
      target.toggle();
      var t = that.html();
      if(text == '') {
        that.remove();
      } else {
        that.html(text);
        text = t;
      }
    });
  });

  find('[data-href]').each(function() {
    var el = $(this);
    el.load(el.attr('data-href'), function() {
      liveBehaviors.call(el.get());
    });
  });

  find('[data-gmap]').gmap();
  
  if($.fn.locator) {
    find('.locator').locator();
  }
  
  find('.via_ajax').viaAjax();
  
  find('.via_ajax_replace').each(function() {
    var form = $(this);
    form.viaAjax({
      disable: true,
      dataType: 'html',
      success: function(html) {
        html = $(html);
        form.replaceWith(html);
        // each, in case the returned mark-up has more than one root
        html.each(function() {
          liveBehaviors.call(this, true);
        });
      }
    });
  });

  find('.submit_on').each(function() {
    var el = $(this);
    var form = el.closest('form');

    var delay;
    if(delay = el.attr('data-delay')) {
      delay = parseInt(delay);
    }

    var timeout = null;
    var callback = function() {
      if(delay && delay > 0) {
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(function() {
          form.submit();
        }, delay);
      } else {
        form.submit();
      }
    };

    if(el.hasClass('_select')) el.select(callback);
    if(el.hasClass('_focusout')) el.focusout(callback);
    if(el.hasClass('_keyup')) el.keyup(callback);
    if(el.hasClass('_change')) el.change(callback);
  });

  find('.bigclick').click(function(e) {
    var events = $.data(e.target, 'events');
    if(e.target == this || !events || !events.click || events.click.length == 0) {
      var t = e.target.tagName;
      // this list may need to be expanded
      if(e.target == this || (t != 'A' && t != 'INPUT' && t != 'TEXTAREA' && t != 'BUTTON')) {
        $(this).find('.bigclick-target').click();
      }
    }
  });

  find('a.bigclick-target').click(function() {
    var href = $(this).attr('href');
    if(href) {
      window.location.href = href;
    }
  });

  find('.checklist').each(function() {
    var el = $(this);

    // insert select all/none controls
    var sAll = $('<button>Select All</button>');
    var sNone = $('<button>Select None</button>');
    $('<p></p>').append(sAll, ' ', sNone).insertBefore(el);

    sAll.click(function(e) {
      e.preventDefault();
      el.find('input[type=checkbox]').each(function() {
        $(this).attr('checked', true);
      });
    });

    sNone.click(function(e) {
      e.preventDefault();
      el.find('input[type=checkbox]').each(function() {
        $(this).removeAttr('checked');
      });
    });
  });

  find('.translate-vars a').click(function(e) {
    e.preventDefault();
    $(this).closest('.translate > li').find('.translate-value').insertAtCaret('%{'+this.innerText+'}');
  });
}

// begin document.ready
$(function() {

  liveBehaviors.call(document);

  // uploadProgress
  if($.fn.uploadProgress) {
    $('form').has('.show_progress').each(function() {
      var form = $(this);
      var progress = form.find('.upload-progress');
      var fill = form.find('.upload-progress-fill');
      var message = form.find('.upload-progress-percents');
      form.uploadProgress({
        start: function() {
          progress.css('display', 'block');
          message.html('Preparing upload...');
        },
        uploading: function(upload) {
          message.html(upload.percents < 100 ? upload.percents+'%' : 'Finishing...');
        },
        complete: function() {
          message.html('Finishing...');
        },
        progressBar: fill,
        progressUrl: "/progress",
        interval: 1000
      });
    });
  }

  // tinyMCE
  if($.fn.tinymce) {
    $(".text.html").each(function() {
      $(this).tinymce({
        // Location of TinyMCE script
        script_url : '/javascripts/tiny_mce/tiny_mce.js',
        // theme : "simple",
        theme : "advanced",
        plugins : "save,advhr,advlink,paste,fullscreen,noneditable,template,advlist,advimage,uploadimage,table",
        convert_urls : false,
        browser_spellcheck : true,
        // Populates a list but selecting doesn't do anything
        //theme_advanced_styles : "Header 1=header1;Header 2=header2;Header 3=header3;Table Row=tableRow1",
        // Populates a list but throws errors "p.getAttribute is not a function" when selecting from styleselect button.
        // Works for table class dropdown though.
        //content_css : "/stylesheets/bootstrap-select.min.css",


        // Theme options
        /*
        theme_advanced_buttons1 : "bold,italic,forecolor,formatselect,separator,pastetext,pasteword,separator,"+
                                  "bullist,numlist,separator,justifyleft,justifycenter,justifyright,separator,"+
                                  "undo,redo,separator,link,unlink,hr,"+
        //                          "removeformat,cleanup,fullscreen,help,code,"+($(this).is('.images') ? 'image' : ''),      
                                  "removeformat,cleanup,fullscreen,help,code,"+($(this).is('.images') ? 'separator,image,separator,uploadimage' : ''),      
        */
        theme_advanced_buttons1 : "newdocument,separator,bold,italic,underline,strikethrough,separator,justifyleft,justifycenter,justifyright,justifyfull,separator,"+
                                  "formatselect,fontselect,fontsizeselect", // leave off styleselect as I'm not even sure it's meant to work in TinyMCEv3.
        theme_advanced_buttons2 : "cut,copy,paste,separator,bullist,numlist,separator,outdent,indent,blockquote,separator,undo,redo,separator,"+
                                  "link,unlink,code,separator,forecolor,backcolor",
        theme_advanced_buttons3 : "table,separator,removeformat,separator,sub,sup,separator,fullscreen,"+
                                  // the following are not in Greg's example
                                  "separator,hr,help,"+($(this).is('.images') ? 'separator,image,separator,uploadimage' : ''),
        theme_advanced_toolbar_location : "top",
        theme_advanced_toolbar_align : "left",
        theme_advanced_resizing : true,
        theme_advanced_resizing_use_cookie: false,
        theme_advanced_resize_horizontal : false,
        width: '100%',

        // Drop lists for link/image/media/template dialogs
        template_external_list_url : "lists/template_list.js",
        external_link_list_url : "lists/link_list.js",
        external_image_list_url : "/admin/assets.js",
        media_external_list_url : "lists/media_list.js",
        external_media_list_url : "lists/media_list.js",

        // Replace values for the template plugin
        template_replace_values : {
          username : "Some User",
          staffid : "991234"
        },

        // The tinymce_assets plugin defaults to POSTing to /tinymce_assets. We want this under admin:
        uploadimage_form_url: '/admin/tinymce_assets',

        // Add a class to the uploaded image tags to tell them apart from images uploaded in other ways
        // (It's there in case we find a use for it...)
        uploadimage_default_img_class: 'tinymce_uploaded',

        oninit: function() {
          $(document).trigger('tinyready');
        }
      });
    });
  } else {
    $(document).trigger('tinyready');
  }

  if($.fn.fancybox) {
    $('a.reveal').fancybox({
      'titlePosition'   : 'inside',
      'transitionIn'    : 'elastic',
      'transitionOut'   : 'elastic'
    });

    $("a.iframe").fancybox({
      'width'       : '85%',
      'height'      : '85%',
      'autoScale'     : false,
      'transitionIn'    : 'elastic',
      'transitionOut'   : 'elastic',
      'type'        : 'iframe'
    });
  }

  $('select#team_id').change(function() {
    var form = $(this).closest('form');
    $.get('/teams/'+$(this).val()+'/show_for_request', function(data) {
      unobscure(form.find('#team_request_display').html(data));
    });
  }).change();

  $('select#role_position').change(function() {
    var form = $(this).closest('form');
    var selected = $(this).val();
    $('option', this).each(function() {
      val = $(this).attr('value');
      optDiv = $('.role_'+val+'_options', form);
      if(optDiv.length > 0) {
        val == selected ? optDiv.show() : optDiv.hide();
      }
    });
  }).change();

  if($.fn.gmap) {
    $('.gmap_have_data').gmap();
  }

  $('.autoscroll').each(function() {
    var el = $(this);
    var within = el.attr('data-within');
    within = within ? $('#'+within) : el.parent();
    
    var placeholder = $('<div class="autoscroll-placeholder"></div>').css({
      margin: 0,
      padding: 0,
      display: el.css('display'),
      width: el.width(),
      height: el.height()
    });
    
    var fixed = $('<div class="autoscroll-fixed"></div>');

    var displaced = false;
    $(window).scroll(function() {
      var winTop = $(this).scrollTop(), winHeight = $(this).height();
      var withinTop = within.offset().top, withinHeight = within.height();

      // irrelevant if "within" is completely above or below the window
      var relevant = winTop <= withinTop+withinHeight && withinTop <= winTop+winHeight;

      var test = displaced ? placeholder : el;
      var testTop = test.offset().top, testHeight = test.height();
      var allInView = testTop >= winTop && testTop+testHeight <= winTop+winHeight;

      if(displaced) {
        if(!relevant || allInView) {
          // restore to original
          placeholder.replaceWith(el);
          fixed.remove();
          displaced = false;
        }
      } else {
        if(relevant && !allInView) {
          // displace to fixed
          el.replaceWith(placeholder);
          fixed.appendTo(within);
          el.appendTo(fixed);
          displaced = true;
        }
      }
    }).scroll();
  });
  
  $(function() {
      $( ".mydatepicker" ).datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: "-70:+5", // last hundred years
        dateFormat: 'yy-mm-dd'        
      });
  });

  if($.fn.datepicker) {
    $(".datepicker").each(function() {
      opts = {
        autoSize: true,
        dateFormat: "yy-mm-dd"

      };
      
      if($(this).hasClass('highlight_days')) {
        opts['beforeShowDay'] = function(date) {
          year = date.getFullYear();
          month = date.getMonth()+1;
          day = date.getDate();
          if(month < 10) { month = "0"+month; }
          if(day < 10) {day = "0"+day; }
          date = year+"-"+month+"-"+day
          cls = highlight_days[date] ? 'highlighted_date' : "";
          tooltip = highlight_days[date] ? highlight_days[date] : null;
          return [true, cls, tooltip];
        };
        opts['onSelect'] = function(dateText, inst) {
          location.href='/events?featured=2&range_start='+dateText;
        };
      }
      
      $(this).datepicker(opts);
    });
  }
  
  if($.fn.datetimepicker) {
    $('.datetimepicker').each(function() {
      $(this).datetimepicker({
        timeFormat: 'hh:mm:ss',
        separator: ' ',
        dateFormat: "yy-mm-dd"
      });
    });
  }

  if($.timeago) {
    $.timeago.settings.allowFuture = true;
    $('input.timeago').each(function() {
      var display = $('<span class="timeago-display"></span>');
      $(this).after(display);
      $(this).change(function() {
        display.html($.timeago(this.value));
      }).change();
    });
  }
  
  $('.date_range').each(function() {
    start = $('.date_range_start', this);
    end = $('.date_range_end', this);
    var autoset = false;
    start.change(function() {
      if(end.val() < $(this).val() || $(this).val() == '') {
        end.val($(this).val());
        // avoid infinite loop
        if(!autoset) {
          autoset = true;
          end.change();
          autoset = false;
        }
      }
    });
    end.change(function() {
      if(start.val() > $(this).val() || $(this).val() == '') {
        start.val($(this).val());
        // avoid infinite loop
        if(!autoset) {
          autoset = true;
          start.change();
          autoset = false;
        }
      }
    });
  });
  
  $('a.toggle_newsworthy').each(function() {
    var that = $(this);
    var nwt = function(e) {
      e.preventDefault();
      $.post(that.attr('href'), function(res) {
        if('newsworthy' in res) {
          that.text(res['newsworthy'] ? 'remove from news feed' : 'add to news feed');
        }
        that.one('click', nwt);
      });
    }
    that.one('click', nwt);
  });
  
  $('a.toggle_feature_status').each(function() {
    var that = $(this);
    var nwt = function(e) {
      e.preventDefault();
      $.post(that.attr('href'), function(res) {
        if('feature_status' in res) {
          that.text(res['feature_status'] == "Suppress" ? 'increase visibility' : 'decrease visibility');
        }
        that.one('click', nwt);
      });
    }
    that.one('click', nwt);
  });


  $('a.toggle_featureworthy').each(function() {
    var that = $(this);
    var nwt = function(e) {
      e.preventDefault();
      $.post(that.attr('href'), function(res) {
        if('super_feature_event' in res) {
          that.text(res['super_feature_event'] ? 'remove from featured list' : 'add to featured list');
        }
        that.one('click', nwt);
      });
    }
    that.one('click', nwt);
  });

  $('a.toggle_marqueeworthy').each(function() {
    var that = $(this);
    var nwt = function(e) {
      e.preventDefault();
      $.post(that.attr('href'), function(res) {
        if('add_to_main_page_carousel' in res) {
          that.text(res['add_to_main_page_carousel'] ? 'remove from front page carousel' : 'add to front page carousel');
        }
        that.one('click', nwt);
      });
    }
    that.one('click', nwt);
  });


  $('.team_event_selector').each(function() {
    team_s = $('.team_selector', this);
    event_s = $('.event_selector', this);
    team_s.change(function() {
      if(team_s.val() != "") {
        $.ajax({
          url:'/teams/'+team_s.val()+'/event_options',
          dataType:'html',
          success:function(data) {
            event_s.html(data);
            event_s.closest('p').show();
          }
        });
      } else {
        event_s.closest('p').hide();
        event_s.html('');
      }
    });
  });
  
  // A select with class revealing_select shows an element with ID equal to the selected value and hides others.
  $('select.revealing_select').change(function() {
    var i, el;
    for(i = 0; i < this.options.length; i++) {
      el = $('#'+this.options[i].value.replace(/\s/g, '_'));
      if(i == this.selectedIndex) {
        el.show();
      } else {
        el.hide();
      }
    }
  }).change();
  
  $('.paginator_links').each(function() {
    $(this).addClass('flickr_pagination');
    var links = $(this);
    var current = parseInt($('.current', links).html());
    
    update_paginator_links(links, current, 4);
    
    $('a', this).click(function() {
      var selected_page = parseInt($(this).html());
      $.ajax({
        url: this.href,
        error: function(res) {
          newurl = window.location.pathname+'?'+$.param(update_querystring({page: selected_page}));
          location.href = newurl;
        },
        success: function(res) {
          $('.paginator_content', links.closest('.paginator')).html(res);
          update_paginator_links(links, selected_page, 4);
        }
      });
      return false;
    });
  });

  /* updates the browser location when a tab is clicked, so the tab will be re-loaded
   * when the user returns to the page */

  // $('#tabs').tabs();

  $('#tabs ul li a').click(function(e) {
      var pos = $(window).scrollTop();
      location.hash = $(this).attr('href');
      $(window).scrollTop(pos);
  });


  //   $('.tabs a').click(function(event) { 
  //  location.hash = $(this).attr('href'); 
  // });

  unobscure(document)


  if($.fn.sortable) {
    $(".sortable").sortable({
      placeholder: 'placeholder',
      update: function(event, ui) {
        $.ajax({
          type: 'post',
          data: $(".sortable").sortable('serialize'),
          dataType: 'script',
          complete: function(request) {
            $(".sortable").effect('highlight');
          },
          url: '/lists/position'
        });
      },
      axis: 'y',
      handle: '.handle'
    });
  }

  // if($.fn.sortable) {
  //   $(".pf_sortable").sortable({
  //     placeholder: 'placeholder',
  //     update: function(event, ui) {
  //       $.ajax({
  //         type: 'post',
  //         data: $(".pf_sortable").sortable('serialize'),
  //         dataType: 'script',
  //         complete: function(request) {
  //           $(".pf_sortable").effect('highlight');
  //         },
  //         url: '/project_fields/position'
  //       });
  //     },
  //     axis: 'y',
  //     handle: '.handle'
  //   });
  // }



  //Slide up and down on click
  $('h2.trigger').each(function(){
    var trigger = $(this);
    var container = trigger.next('.toggle_container_rwd');

    if(!container.is(':hidden')) {
      trigger.addClass('active');
    } else {
      trigger.removeClass('active');
    }
    
    $(this).click(function(e) {
      e.preventDefault();
      container.slideToggle('slow');
      trigger.toggleClass('active');
    });
  });

  // collapse starthidden on load
  $('.trigger.starthidden').click();




  //Slide up and down on click
  $("h2.trigger_left_align").each(function(){
    var trigger = $(this);
    var container = trigger.next('.toggle_container_left_align');

    if(!container.is(':hidden')) {
      trigger.addClass('active');
    } else {
      trigger.removeClass('active');
    }
    
    $(this).click(function(e) {
      e.preventDefault();
      container.slideToggle('slow');
      trigger.toggleClass('active');
    });
  });

  // collapse starthidden on load
  $('.trigger_left_align.starthidden').click();



  var updating = new Object();

  //$('[data-method]').ajaxMethod();

  //more dependent stuff
  var media_type_parent_select = $('#media_type_parent select');
  var media_preview_parent     = $('#media_preview_parent');


  if(media_type_parent_select.val() === 'Picture'){
    media_preview_parent.fadeOut(0);
  }

  $(document).on('change', '#media_type_parent select', function(){
    var that = $(this);
    var target = $('#media_preview');

    if(that.val() === 'Picture'){
      media_preview_parent.fadeOut();
      $('input',media_preview_parent).val('');
    }else{
      media_preview_parent.fadeIn();
    }
  });

  // tag-it
  $('.tagit-search').each(function() {
    tagit_search(this);
  });
  
  // full calendar
  // $('#calendar').fullCalendar({
  //  header: {
  //    left: 'prev,next today',
  //    center: 'title',
  //    right: 'month,agendaWeek,agendaDay'
  //  },
  //  editable: false,
  //  events: '/calendarevents'
  // })
  

});
// end document.ready
