// https://chowdera.com/2021/07/20210729234337264c.html
import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    $(this.element).slick({
      arrows: this.data.get("arrows"),
      dots: this.data.get("dots"),
      autoplaySpeed: parseInt(this.data.get("autoplaySpeed")),
      slidesToShow: parseInt(this.data.get("slidesToShow")),
      slidesToScroll: parseInt(this.data.get("slidesToScroll")),
      infinite: true,
      autoplay: true,
      speed: 300,
      cssEase: 'linear'
    });
  }

  disconnect() {
    $(this.element).slick('unslick');
  }
}