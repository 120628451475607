import { Controller } from "stimulus"
import TomSelect from "tom-select"
// import "tom-select/dist/css/tom-select.css"
// This fixed TomSelect https://github.com/orchidjs/tom-select/issues/492

// PROVIDENCE - updated March 9th 2024
// DO NOT DELETE Active in the following as ". data: { controller: "tomselect" }"
// 1) app/views/segment_publication_feeds/_segment_publication_feed_dropper.html.erb


// Connects to data-controller="tomselect"
export default class extends Controller {

  static targets = [ 'tagField', 'output' ];


  connect() {
    this.outputTarget.textContent = 'Hello, MRPload!'

    if (this.hasTagFieldTarget) {
      this.outputTarget.textContent = 'Hello, MRP'
      this.initTomSelect()
    }
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
      this.outputTarget.textContent = 'Hello, MRPz closed!'
    }
  }

  initTomSelect() {

    // var eventHandler = function(name) {
    //     return function() {
    //         var form = document.getElementById('resourceForm');
    //         var sal = arguments[0]
    //         form.setAttribute('action', '/resources');
    //         var input = document.createElement("input");
    //         input.type = "text";
    //         input.name = "tag_id";
    //         input.value = sal;
    //         form.appendChild(input);
    //         input.setAttribute("style", "display : none");
    //         document.getElementById('resourceForm').submit()
    //     };
    // };
    // var config = {
    //     allowEmptyOption: false,
    //     create: false,
    //     onItemAdd: eventHandler('onItemAdd'),
    // };
    // new TomSelect('#e1',config);



    // new TomSelect(this.tagFieldTarget, {
    //   var eventHandler = function(name) {
    //       return function() {
    //           var form = document.getElementById('resourceForm');
    //           var sal = arguments[0]
    //           form.setAttribute('action', '/resources');
    //           var input = document.createElement("input");
    //           input.type = "text";
    //           input.name = "tag_id";
    //           input.value = sal;
    //           form.appendChild(input);
    //           input.setAttribute("style", "display : none");
    //           document.getElementById('resourceForm').submit()
    //       };
    //   };
    //   var config = {
    //       allowEmptyOption: false,
    //       create: false,
    //       onItemAdd: eventHandler('onItemAdd'),
    //   };
    // });
  }



}
