// app/assets/javascripts/modals.js



$(function() {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function() {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(
      location,
      data => { $(modal_holder_selector).html(data).find(modal_selector).modal('show') }
    );
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function(event){
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal();
    }

    return false;
  });
});




// https://www.workversatile.com/jquery-to-javascript-converter

// document.addEventListener("DOMContentLoaded", () => {  
//   const modal_holder_selector = '#modal-holder';
//   const modal_selector = '.modal';

//   document.on('click', 'a[data-modal]', function() {
//     const location = this.attr('href');
//     // console.log = "GGGGGGGG => click is noted" 
//     $.get(
//       location,
//       data => { modal_holder_selector.innerHTML = data.find(modal_selector).modal() }
//     );
//     return false;
//   });

//   document.on('ajax:success', 'form[data-modal]', function(event){
//     const [data, _status, xhr] = event.detail;
//     const url = xhr.getResponseHeader('Location');
//     if (url) {
//       // console.log = "GGGGGGGGGGGGGGGGGG"      
//       // console.log = url
//       // window.location = url;

//     } else {
      
//       // let modal-backdrop = document.getElementByClassName("modal-backdrop");
//       // modal-backdrop.parentNode.removeChild(modal-backdrop);
      
//       // const modal = data.find('body').innerHTML;
//       // modal_holder_selector.innerHTML = modal.find(modal_selector).modal();
//     }

//     return false;
//   });

// });


// // document.querySelector(function() {
// //   const modal_holder_selector = '#modal-holder';
// //   const modal_selector = '.modal';

// //   document.querySelector(document).addEventListener('click', 'a[data-modal]', function() {
// //     const location = document.querySelector(this).attr('href');
// //     // Load modal dialog from server
// //     $.get(
// //       location,
// //       data => { document.querySelector(modal_holder_selector).html(data).querySelector(modal_selector).modal() }
// //     );
// //     return false;
// //   });

// //   document.querySelector(document).addEventListener('ajax:success', 'form[data-modal]', function(event){
// //     const [data, _status, xhr] = event.detail;
// //     const url = xhr.getResponseHeader('Location');
// //     if (url) {
// //       // Redirect to url
// //       window.location = url;
// //     } else {
// //       // Remove old modal backdrop
// //       document.querySelector('.modal-backdrop').remove();
// //       // Update modal content
// //       const modal = document.querySelector(data).querySelector('body').html();
// //       document.querySelector(modal_holder_selector).html(modal).querySelector(modal_selector).modal();
// //     }

// //     return false;
// //   });
// // });





