import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import "slim-select/dist/slimselect.css"


export default class extends Controller {
  static values = { url: String }

  connect() {
    let _this = this
    new SlimSelect({
      select: this.element,
      // searchingText: "Searching...",
      // ajax: function (search, callback) {
      //   if (search.length < 0) {
      //     callback('Need 3 characters')
      //     return
      //   }

      //   let url = new URL(_this.urlValue)
      //   url.search = new URLSearchParams({ query: search })

      //   fetch(url)
      //     .then(response => response.json())
      //     .then(json => callback(json))
      //     .catch(error => callback(error))
      // }

    })
  }
}

  // Working examples 

  // :include_blank !!!!!!!
      // <div class="form-group">
      //   <%= f.label "Nested under" %>
      //   <%= f.collection_select(:parent_id, Client.all, :id, :name, {:include_blank => true}, multiple: false, data: { controller: :slim }) %>
      // </div>
      // <br />


  // <div class="form-group">
  //   <%= f.label "Team this feed can auto-pulls publications from" %>
  //   <%= f.collection_select(:team_id, Team.all, :id, :name, {}, data: { controller: :slim }) %>
  // </div>


  // <div class="form-group">
  //   <%= form.label "Regions" %>
  //   <%= form.collection_select(:region_ids, Region.all, :id, :name, {}, multiple: true, data: { controller: :slim }) %>
  // </div>


  // <div class="form-group">
  //   <%= collection_select(:user_ids, :user_ids, User.active_users, :id, :name, {},
  //       multiple: true, data: { controller: :slim }) %>
  // </div>







