/**
 * Character counter and limiter plugin for textfield and textarea form elements
 * @author Sk8erPeter
 */ 
(function ($) {
  $.fn.characterCounter = function (params) {
    // merge default and user parameters
    params = $.extend({
      // define maximum characters
      maximumCharacters: 1000,
      // create typed character counter DOM element on the fly
      characterCounterNeeded: true,
      // create remaining character counter DOM element on the fly
      charactersRemainingNeeded: true,
      // chop text to the maximum characters
      chopText: false,
      // place character counter before input or textarea element
      positionBefore: false,
      // class for limit excess
      limitExceededClass: "character-counter-limit-exceeded",
      // suffix text for typed characters
      charactersTypedSuffix: " characters typed",
      // suffix text for remaining characters
      charactersRemainingSuffixText: " characters left",
      // whether to use the short format (e.g. 123/1000)
      shortFormat: false,
      // separator for the short format
      shortFormatSeparator: "/"
    }, params);

    // traverse all nodes
    this.each(function () {
      var $this = $(this),
        $pluginElementsWrapper,
        $characterCounterSpan,
        $charactersRemainingSpan;

      // return if the given element is not a textfield or textarea
      if (!$this.is("input[type=text]") && !$this.is("textarea")) {
        return this;
      }

      // create main parent div
      if (params.characterCounterNeeded || params.charactersRemainingNeeded) {
        // create the character counter element wrapper
        $pluginElementsWrapper = $('<div>', {
          'class': 'character-counter-main-wrapper'
        });

        if (params.positionBefore) {
          $pluginElementsWrapper.insertBefore($this);
        } else {
          $pluginElementsWrapper.insertAfter($this);
        }
      }

      if (params.characterCounterNeeded) {
        $characterCounterSpan = $('<span>', {
          'class': 'counter character-counter',
          'text': 0
        });

        if (params.shortFormat) {
          $characterCounterSpan.appendTo($pluginElementsWrapper);

          var $shortFormatSeparatorSpan = $('<span>', {
            'html': params.shortFormatSeparator
          }).appendTo($pluginElementsWrapper);

        } else {
          // create the character counter element wrapper
          var $characterCounterWrapper = $('<div>', {
            'class': 'character-counter-wrapper',
            'html': params.charactersTypedSuffix
          });

          $characterCounterWrapper.prepend($characterCounterSpan);
          $characterCounterWrapper.appendTo($pluginElementsWrapper);
        }
      }

      if (params.charactersRemainingNeeded) {

        $charactersRemainingSpan = $('<span>', {
          'class': 'counter characters-remaining',
          'text': params.maximumCharacters
        });

        if (params.shortFormat) {
          $charactersRemainingSpan.appendTo($pluginElementsWrapper);
        } else {
          // create the character counter element wrapper
          var $charactersRemainingWrapper = $('<div>', {
            'class': 'characters-remaining-wrapper',
            'html': params.charactersRemainingSuffixText
          });
          $charactersRemainingWrapper.prepend($charactersRemainingSpan);
          $charactersRemainingWrapper.appendTo($pluginElementsWrapper);
        }
      }

      $this.keyup(function () {

        var typedText = $this.val();
        var textLength = typedText.length;
        var charactersRemaining = params.maximumCharacters - textLength;

        // chop the text to the desired length
        if (charactersRemaining < 0 && params.chopText) {
          $this.val(typedText.substr(0, params.maximumCharacters));
          charactersRemaining = 0;
          textLength = params.maximumCharacters;
        }

        if (params.characterCounterNeeded) {
          $characterCounterSpan.text(textLength);
        }

        if (params.charactersRemainingNeeded) {
          $charactersRemainingSpan.text(charactersRemaining);

          if (charactersRemaining <= 0) {
            if (!$charactersRemainingSpan.hasClass(params.limitExceededClass)) {
              $charactersRemainingSpan.addClass(params.limitExceededClass);
            }
          } else {
            $charactersRemainingSpan.removeClass(params.limitExceededClass);
          }
        }
      });

    });

    // allow jQuery chaining
    return this;

  };
})(jQuery);