// app/javascript/components/selectize.js
import 'selectize/dist/js/selectize.min.js'; // scroll to node folder (1) get that path
import 'selectize/dist/css/selectize.css'; // scroll to node folder (1) get that path

const selectize = () => {
  $('.select-beast').selectize({
      // create: true, (from example on selectize page)
      sortField: 'text'
  });
  $('#select-item').selectize({
      // create: true, (from example on selectize page)
      sortField: 'text'
  });
};

export { selectize }; //# app/javascript/packs/application.js will call that const
