
import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      handle: ".handle",
    
    })
  }

  end(event) {
    // console.log(event)
    // let id = event.item.dataset.id // NOT NEEDED
    // let ids = event.from.children

    var ids = []

    // Array.from(event.from.children).forEach(function (element) {
    //         //console.log(element.getAttribute('data-id'))
    //         ids.push(parseInt(element.getAttribute('data-id')))
    //       });

    Array.from(event.from.children).forEach(function (element) {
            //console.log(element.getAttribute('data-id'))
            ids.push(element.getAttribute('data-id'))
          });

    // console.log(id)
    // console.log('new-line')
    // console.log(ids)


    // This piece of magic is where you build your Form data - you get to set the param names and append
    // their values - this is fckn awesome 
    let data = new FormData()
    data.append("erz", ids)

      // url: '/beaconings/update_beaconings_position'
      // url: '/compendia/update_external_resource_position',

    // This piece of magic is where you send your form data by Post to a real rails controller
    Rails.ajax({
      url: this.data.get("url"),
      type: 'POST',
      data:data
    })


  }
}