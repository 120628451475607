// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"; // this is official canon ([x] implemented)
import "@hotwired/turbo-rails"; // not canon
//import Turbolinks from "turbolinks" // this is official canon ([] not yet implemented)
// import { Turbo } from "@hotwired/turbo-rails"; // I break jQuery
import * as ActiveStorage from "@rails/activestorage"; // this is official canon ([x] implemented)
import "channels"; // this is official canon ([x] implemented)

Rails.start(); // this is official canon ([x] implemented)
// Turbolinks.start() // this is official canon ([] not yet implemented)
ActiveStorage.start(); // this is official canon ([x] implemented)

import "@hotwired/stimulus";
import "controllers";


// >> turned off March 10 2024
// Turbo.session.drive = true;
// << turned off March 10 2024


// 3rd Party
// 3rd Party
// 3rd Party

import "core-js/stable"; // not canon
import "regenerator-runtime/runtime"; // not canon

// the tom-select css does not compile cleanly so we use the CDN;
import 'tom-select';

// import { createPopper } from '@popperjs/core';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap';
window.bootstrap = require('bootstrap/dist/js/bootstrap');

// import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
window.AOS = require('aos/dist/aos.js');

import 'animate.css';

import "@fortawesome/fontawesome-free/js/all";

import { selectize } from '../components/selectize';
selectize();

// works GV July 2022
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'highcharts/highcharts.js';

import 'fullcalendar';

import 'moment';

import { flatpickr } from '../components/flatpickr';

import Picker from 'vanilla-picker/dist/vanilla-picker.min.js'; // scroll to node folder (1) get that path


// Legacy Hand Crafted
// Legacy Hand Crafted
// Legacy Hand Crafted


import '../components/legacy/application.js';
import '../components/legacy/modal.js';
import { characterCounter } from '../components/legacy/characterCounter.js';


// jquery
// jquery
// jquery

import $ from 'jquery';
window.$ = window.jQuery = $;
// import {} from 'jquery-ujs' // you cannot run this and rails-ujs (rails-ujs is needed for global javascript)
global.$ = $;
global.jQuery = $;
require('jquery-ui');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/sortable');
require('jquery-autosize');
require('jquery-match-height');

document.addEventListener('DOMContentLoaded', function() {
  // flatpickr('.packflatpickr'); // does not work

  // works
  $('.datetime-pack-flatpickr').flatpickr({
    enableTime: true,
  });

  $('.datetime-pack-flatpickr-starts').flatpickr({
    enableTime: true,
  });

  $('.datetime-pack-flatpickr-ends').flatpickr({
    enableTime: true,
  });

  // works
  $('.date-pack-flatpickr').flatpickr({
    enableTime: false,
  });


})

// pure javascript
// pure javascript
// pure javascript

document.addEventListener("turbo:load", () => {
    console.log('Page turbo:load');
})

document.addEventListener("turbo:render", () => {
    console.log('Page turbo:render');
})


// scss
// scss
// scss

import '../stylesheets/stylePicker.scss';
