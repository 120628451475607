import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/classic.min.css";

export default class extends Controller {
  static targets = ["picker", "input"]

  initialize() {
    this.initPicker();
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'classic',
      default: this.inputTarget.value,

      components: {
        preview: true,
        opacity: true,
        hue: true,

        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
    });

    this.picker.on('save', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();

      this.picker.hide();
    });
  }
}

// Typical Use
// <div class="card well card-body">
//  <div class="form-group" data-controller="color-picker">
//    <%= f.text_field :events_text_color, :class => "pickr", data: { target: 'color-picker.input' } %>
//    <span data-target="color-picker.picker"></span>
//    <%= f.label :events_text_color %>
//  </div>
// </div>

// CSS Depedencies

// .. in /common.css
// form .form-group .pickr {
//   display: inline-block;
// }


// Hunt down and refactor

// <div id="color_text_field" style="zzdisplay:none;">  
//   <div class="form-group">
//     <%= f.label :text_color %>
//     <%= f.text_field :text_color, :id => "text_color", :class => "form-control" %>
//         <button id="text_color_button">choose color</button>
//   </div>
// </div>



