// https://gorails.com/forum/how-to-initialize-datepicker-in-stimulus-controller

import Flatpickr from 'stimulus-flatpickr'
export default class extends Flatpickr {

  connect() {
    //global options
    this.config = {
      ...this.config, //spread options in case some where defined in initialize
      enableTime: true,
      time_24hr: true
    };

    document.addEventListener("focusin", this.getFocus);
    // document.addEventListener('focusin', (e) => {
    // //not equal to null
    // if (e.target.closest(".flatpickr-calendar") !== null) {
    // e.stopImmediatePropagation();
    // }
    // });
    //always call super.connect()
    super.connect();
  }

  disconnect() {
    document.removeEventListener("focusin", this.getFocus);
  }

  getFocus= (e) => {
    if (e.target.closest(".flatpickr-calendar") !== null) {
      e.stopImmediatePropagation();
    }
  }
    // https://github.com/adrienpoly/stimulus-flatpickr
}