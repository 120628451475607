import { Controller } from "stimulus"
import TomSelect from "tom-select"
// import "tom-select/dist/css/tom-select.css"
// This fixed TomSelect https://github.com/orchidjs/tom-select/issues/492

// Connects to data-controller="tomselect"
export default class extends Controller {
  connect() {
    this.initTomSelect()

  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    if (this.element) {
        this.select = new TomSelect(this.element, {
      })
    }
  }



}
