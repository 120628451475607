// app/javascript/controllers/selectfire_controller.js

// Credits => https://www.reddit.com/r/rubyonrails/comments/miozor/how_to_return_selected_option_dataattribute_in/

import { Controller } from "stimulus";
import TomSelect from "tom-select";

export default class extends Controller {

  static targets = [ 'tagField', 'output' ];
  static values = { currenttagid: String };


  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
      this.initializeTomSelect();
  }

  handleChange() {

    // console.log('handleChange triggered');
    // console.log(this.tagFieldTarget.selectedOptions[0].value);

    let td = this.tagFieldTarget.selectedOptions[0].value;
    let length = td.length;

    if (length > 1) {

      var form = document.getElementById('resourceForm');
      let sal = td;
      form.setAttribute('action', '/resources');
      var input = document.createElement("input");
      input.type = "text";
      input.name = "tag_id";
      input.value = sal;
      form.appendChild(input);
      input.setAttribute("style", "display : none");
      document.getElementById('resourceForm').submit()

    } else {
      // console.log('the tagid is zero' + tagId );
    }


  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }


  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    this.select = new TomSelect(this.element, {
      allowEmptyOption: false,
      create: false,
      maxItems: 1
    });
  }

  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}