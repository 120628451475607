//import { Controller } from "@hotwired/stimulus";
import { Controller } from 'stimulus'

// Connects to data-controller="modals"
export default class extends Controller {
  connect() {}

  close(e) {
    // Prevent default action
    e.preventDefault();

    // Remove from parent
    const modal = document.getElementById("ktemodal");
    modal.innerHTML = "";

    // Remove the src attribute from the modal
    modal.removeAttribute("src");

    // Remove complete attribute
    modal.removeAttribute("complete");
  }
}